import React from 'react'
import { Link } from 'gatsby'

import Image from 'Image'
import CategoriesRoll from 'CategoriesRoll'

const BlogRollViewItem = ({ title, excerpt, featureImage, slug, tags, className }) => (
  <article
    className={`c-article-block ${className}`}
  >
    <Link
      to={slug}
      className='c-article-block__link u-color-white c-btn__parent'
    >
      <figure
        className='c-article-block__image u-img-full'
      >
        <Image
          image={featureImage.default}
          width={400}
          sizes={[
            `${Image.breakpoint('md')} calc((100vw - 54px) / 2)`,
            '100vw'
          ]}
          alt={``}
        />
      </figure>
      <h3
        className='c-article-block__title u-color-black u-margin-bottom-small'
      >
        {title}
      </h3>
      {
        tags &&
          <div
            className='u-margin-bottom-small'
          >
            <CategoriesRoll
              categories={tags.map(({ frontmatter }) => frontmatter)}
            />
          </div>
      }
      <p
        className='c-article-block__excerpt u-fs-14 u-color-smoke'
      >{excerpt}</p>
      <span
        className='c-btn'
      >Read Now</span>
    </Link>
  </article>
)

export default BlogRollViewItem
