import React from 'react'

import View from './BlogIndexView'

const BlogIndex = ({ featuredArticles, otherArticles }) => (
  <View
    featuredArticles={featuredArticles}
    otherArticles={otherArticles}
  />
)

export default BlogIndex
