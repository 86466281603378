import React from 'react'
import { Link } from 'gatsby'
import classNames from 'classnames'

import Image from 'Image'
import CategoriesRoll from 'CategoriesRoll'

const BlogRollViewFeaturedItem = ({ title, excerpt, featureImage, tags, slug, className }) => (
  <article
    className={classNames('c-article-block c-article-block--featured u-position-relative', className)}
  >
    <Link
      to={slug}
      className='c-article-block__link u-color-white c-btn__parent'
    >
      <div
        className='o-container'
      >
        <div
          className='c-article-block__content u-position-relative'
        >
          {
            tags &&
              <CategoriesRoll
                categories={tags.map(({ frontmatter }) => frontmatter)}
              />
          }
          <h3
            className='c-article-block__title e-h2 u-margin-bottom-none'
          >
            {title}
          </h3>
          <p
            className='c-article-block__excerpt'
          >{excerpt}</p>
          <span
            className='c-btn c-btn--invert'
          >Read Now</span>
        </div>
      </div>
      <figure
        className='c-article-block__image o-bg-hero-image o-bg-hero-image--13'
      >
        <Image
          image={featureImage.default}
          width={1352}
          sizes={[
            '100vw'
          ]}
          alt={``}
        />
      </figure>
    </Link>
  </article>
)

export default BlogRollViewFeaturedItem
