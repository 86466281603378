import React, { Fragment } from 'react'

import BlogRoll from 'BlogRoll'

const BlogIndexView = ({ featuredArticles, otherArticles }) => (
  <div
    className='c-blog o-shift-for-header'
  >
    {
      featuredArticles &&
      <Fragment>
        <h2
          className='u-hide-visually'
        >
          Featured Articles
        </h2>
        <BlogRoll
          posts={featuredArticles}
        />
      </Fragment>
    }
    {
      otherArticles.length > 0 &&
      <div
        className='o-container c-show-roll'
      >
        <h2
          className='c-show-roll__title u-ta-c'
        >
          From the Archives
        </h2>
        <div
          className='c-blog-grid'
        >
          <BlogRoll
            posts={otherArticles}
            itemClassName='c-blog-grid__item'
          />
        </div>
      </div>
    }
  </div>
)

export default BlogIndexView
