import React from 'react'
import PropTypes from 'prop-types'

import View from './BlogRollView'

const BlogRoll = ({ posts, itemClassName }) => (
  <View
    posts={posts}
    itemClassName={itemClassName}
  />
)

BlogRoll.propTypes = {
  posts: PropTypes.array.isRequired,
  itemClassName: PropTypes.string
}

export default BlogRoll
