import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'

import sanitizer from 'utilities/sanitizer'

import SEO from 'SEO'
import Layout from 'Layout'
import BlogIndex from 'BlogIndex'

const BlogIndexPage = ({ data }) => {
  return (
    <Layout>
      <SEO title='Articles' />
      <StaticQuery
        query={AllBlogArticlesQuery}
        render={data => {
          const { featuredArticles, otherArticles } = data
          const { edges: featuredArticleEdges } = featuredArticles
          const { edges: otherArticleEdges } = otherArticles

          return (
            <BlogIndex
              featuredArticles={sanitizer(featuredArticleEdges)}
              otherArticles={sanitizer(otherArticleEdges)}
            />
          )
        }}
      />
    </Layout>
  )
}

BlogIndexPage.propTypes = {
  data: PropTypes.object.isRequired
}

const AllBlogArticlesQuery = graphql`
  query AllBlogArticlesQuery {
    featuredArticles: allMarkdownRemark(
      filter: {
        frontmatter: {
          templateKey: {
            eq: "templates/ArticleTemplate"
          }
          isFeatured: {
            eq: true
          }
        }
      }
      sort: {
        fields: [frontmatter___isFeatured]
        order: DESC
      }
    ) {
      edges {
        node {
          id
          excerpt
          fields {
            slug
          }
          frontmatter {
            title
            templateKey
            isFeatured
            publishDate
            featureImage {
              default
            }
            tags {
              frontmatter {
                title
                hex
              }
            }
          }
        }
      }
    }
    otherArticles: allMarkdownRemark(
      filter: {
        frontmatter: {
          templateKey: {
            eq: "templates/ArticleTemplate"
          }
          isFeatured: {
            eq: false
          }
        }
      }
      sort: {
        fields: [frontmatter___publishDate]
        order: DESC
      }
    ) {
      edges {
        node {
          id
          excerpt
          fields {
            slug
          }
          frontmatter {
            title
            templateKey
            isFeatured
            publishDate
            featureImage {
              default
            }
            tags {
              frontmatter {
                title
                hex
              }
            }
          }
        }
      }
    }
  }
`

export default BlogIndexPage
