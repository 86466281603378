import React from 'react'
import PropTypes from 'prop-types'

import BlogRollViewFeaturedItem from './BlogRollViewFeaturedItem'
import BlogRollViewItem from './BlogRollViewItem'

const BlogView = ({ posts, itemClassName }) => (
  posts.map((post, key) =>
    post.isFeatured ? <BlogRollViewFeaturedItem
      key={key}
      className={itemClassName}
      {...post}
    /> : <BlogRollViewItem
      key={key}
      className={itemClassName}
      {...post}
    />
  )
)

BlogView.propTypes = {
  posts: PropTypes.array.isRequired
}

export default BlogView
